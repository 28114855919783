<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">{{headerForm}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        class="form-body"
                        v-model="valid">
                        <div class="d-flex d-flex-space-between">
                            <div class="mr-4">
                                <v-select
                                    v-model="form.branchId"
                                    :items="branchOptions"
                                    label="Branch"
                                    item-text="key"
                                    item-value="value"
                                ></v-select>
                            </div>
                            <div>
                                <v-select
                                    v-model="form.courseId"
                                    :items="courseOptions"
                                    :error-messages="errors.courseId"
                                    item-text="key"
                                    item-value="value"
                                    label="Course"
                                >
                                </v-select>
                            </div>
                        </div>
                        <div class="d-flex d-flex-space-between">
                            <div class="mr-4">
                                <v-checkbox
                                    @change="toggleIsCountHour"
                                    v-model="form.isCountHour"
                                    :error-messages="errors"
                                    label="Count Hour"
                                    type="checkbox"
                                ></v-checkbox>
                            </div>
                            <div>
                                <v-text-field
                                    v-model="form.hours"
                                    :disabled="!form.isCountHour"
                                    suffix="Hr."
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="d-flex d-flex-space-between">
                            <div class="mr-4">
                                <v-menu
                                    v-model="pickerStartDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startDateFormat"
                                            label="Start Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.startDate"
                                        @input="pickerStartDate = false"
                                        @change="formatDateDisplay(form.startDate, 'startDateFormat')"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div>
                                <v-menu
                                    v-model="pickerEndDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="endDateFormat"
                                            label="End Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.endDate"
                                        @input="pickerEndDate = false"
                                        @change="formatDateDisplay(form.endDate, 'endDateFormat')"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div>
                            <v-select
                                v-model="selectDayDisplay"
                                :items="dayOptions"
                                :error-messages="errors.selectDayDisplay"
                                item-text="key"
                                item-value="value"
                                label="Select Day"
                                multiple
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="toggle"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="selectDayDisplay.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                        </div>
                        <div>
                            <v-checkbox
                                v-model="form.isDropCourse"
                                label="Drop Course"
                                type="checkbox"
                                @change="toggleIsDropCourse"
                            ></v-checkbox>
                        </div>
                        <div v-if="form.isDropCourse" class="d-flex d-flex-space-between">
                            <div class="mr-4">
                                <v-menu
                                    v-model="pickerStartDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startDateDropFormat"
                                            label="Start Drop Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.startDropCourse"
                                        @input="pickerStartDate = false"
                                        @change="formatDateDisplay(form.startDropCourse, 'startDateDropFormat')"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div>
                                <v-menu
                                    v-model="pickerEndDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="endDateDropFormat"
                                            label="End Drop Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="inputRules"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.endDropCourse"
                                        @input="pickerEndDate = false"
                                        @change="formatDateDisplay(form.endDropCourse, 'endDateDropFormat')"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="btn-container">
                            <v-btn class="mr-4" @click="submit" color="primary">
                                Submit
                            </v-btn>
                            <v-btn @click="closeModal" color="primary" outlined>
                                Cancel
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'StudentCourseForm',
    props: {
        mode: {
            type: String,
            default: null
        },

        studentCourseId: {
            type: String,
            default: null
        },

        studentId: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            dialog: false,
            valid: false,
            inputRules: [
                v => !!v || 'This field is required'
            ],
            form: {
                branchId: '',
                courseId: '',
                isCountHour: false,
                hours: 0,
                startDate: '',
                endDate: '',
                isDropCourse: false,
                startDropCourse: null,
                endDropCourse: null
            },
            selectDayDisplay: [],
            startDateFormat: '',
            endDateFormat: '',
            startDateDropFormat: '',
            endDateDropFormat: '',
            branchOptions: [],
            courseOptions: [],
            headerForm: 'Create branch',
            errors: [],
            pickerStartDate: false,
            pickerEndDate: false,
            dayOptions: [
                {
                    key: 'Sunday',
                    value: 'sun'
                },
                {
                    key: 'Monday',
                    value: 'mon'
                },
                {
                    key: 'Tuesday',
                    value: 'tue'
                },
                {
                    key: 'Wednesday',
                    value: 'wed'
                },
                {
                    key: 'Thursday',
                    value: 'thu'
                },
                {
                    key: 'Friday',
                    value: 'fri'
                },
                {
                    key: 'Saturday',
                    value: 'sat'
                }
            ]
        }
    },

    computed: {
        selectAllDay () {
            return this.selectDayDisplay.length === this.dayOptions.length
        },

        selectSomeDay () {
            return this.selectDayDisplay.length > 0 && !this.selectAllDay
        },

        icon () {
            if (this.selectAllDay) return 'mdi-close-box'
            if (this.selectSomeDay) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        }
    },
    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },

        async createStudentCourse () {
            const res = await this.axios.post(`/student/${this.studentId}/student-courses`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Create branch success')
                this.$emit('create-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async editStudentCourse () {
            const res = await this.axios.put(`/student/${this.studentId}/student-courses/${this.studentCourseId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Edit branch success')
                this.$emit('edit-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }

            this.form.selectDay = this.selectDayDisplay.toString()
            if (this.mode === 'create') {
                await this.createStudentCourse()
            } else if (this.mode === 'edit') {
                await this.editStudentCourse()
            }
        },

        closeModal () {
            this.dialog = false
            this.resetForm()
            this.$emit('close-modal')
        },

        resetForm () {
            this.$refs.form.reset()
            this.form = {
                branchId: '',
                courseId: '',
                isCountHour: false,
                hours: 0,
                startDate: '',
                endDate: '',
                isDropCourse: false,
                startDropCourse: null,
                endDropCourse: null
            }
        },

        async getStudentCourse () {
            const res = await this.axios.get(`/student/${this.studentId}/student-courses/${this.studentCourseId}`)
            if (res && res.data && res.data.status === 'ok') {
                const data = res.data.data
                this.form = {
                    branchId: data.branch.id,
                    courseId: data.course.id,
                    isCountHour: data.isCountHour,
                    hours: data.hours,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    isDropCourse: data.isDropCourse,
                    startDropCourse: data.startDropCourse,
                    endDropCourse: data.endDropCourse
                }
                this.selectDayDisplay = data.selectDay.split(',')

                this.formatDateDisplay(this.form.startDate, 'startDateFormat')
                this.formatDateDisplay(this.form.endDate, 'endDateFormat')
                this.formatDateDisplay(this.form.startDropCourse, 'startDateDropFormat')
                this.formatDateDisplay(this.form.endDropCourse, 'endDateDropFormat')
            }
        },

        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchOptions = res.data.data
            }
        },

        async getCourseList () {
            const res = await this.axios.get('/courses-select')
            if (res && res.data && res.data.status === 'ok') {
                this.courseOptions = res.data.data
            }
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        formatDateDisplay (date, type) {
            if (type === 'startDateFormat') {
                this.startDateFormat = this.formatDate(date)
            } else if (type === 'endDateFormat') {
                this.endDateFormat = this.formatDate(date)
            } else if (type === 'startDateDropFormat') {
                this.startDateDropFormat = this.formatDate(date)
            } else if (type === 'endDateDropFormat') {
                this.endDateDropFormat = this.formatDate(date)
            }
        },

        toggle () {
            this.$nextTick(() => {
                if (this.selectAllDay) {
                    this.selectDayDisplay = []
                } else {
                    this.selectDayDisplay = this.dayOptions.map(day => day.value)
                }
            })
        },

        toggleIsCountHour () {
            if (!this.form.isCountHour) {
                this.form.hours = 0
            }
        },

        toggleIsDropCourse () {
            if (!this.form.isDropCourse) {
                this.form.startDropCourse = null
                this.form.endDropCourse = null
            }
        }
    },

    watch: {
        async mode (newVal, oldVal) {
            if (newVal === 'create') {
                this.headerForm = 'Create Course'
            } else if (newVal === 'edit') {
                this.headerForm = 'Edit Course'
                await this.getStudentCourse()
            }
        }
    },

    async mounted () {
        await this.getBranchOptions()
        await this.getCourseList()
    }
}
</script>

<style lang="scss" scoped>
.form-body {
    padding: 0 12px;
}
</style>
