<template>
    <div>
        <v-breadcrumbs
            :items="items"
            large
        ></v-breadcrumbs>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-application ul {
    padding-left: 0;
}
::v-deep .v-breadcrumbs {
    padding-left: 0;
}
</style>
