<template>
    <div>
        <breadcrumbs
            :items="breadItem"
        ></breadcrumbs>
        <div class="header-page">
            <div class="header-font">
                Student Courses
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createCourse">Create</v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="studentCourseList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editCourse(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteModal(item)"
                    >
                        mdi-delete
                    </v-icon>
                </div>
            </template>
        </v-data-table>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
        <student-course-form
            @close-modal="resetValueForm"
            @edit-success="getStudentCourseList"
            @create-success="getStudentCourseList"
            :studentId="studentId"
            ref="studentCourseForm"
            :studentCourseId="studentCourseId"
            :mode="mode"
        />
    </div>
</template>

<script>
import DeleteModal from '../components/DeleteModal'
import StudentCourseForm from '../components/StudentCourseForm'
import Breadcrumbs from '../components/Breadcrumbs'
export default {
    name: 'StudentCourses',
    components: {
        DeleteModal,
        StudentCourseForm,
        Breadcrumbs
    },
    data () {
        return {
            studentCourseList: [],
            headers: [
                { text: 'Course', value: 'course' },
                { text: 'Start Date', value: 'startDate' },
                { text: 'End Date', value: 'endDate' },
                { text: 'leave hour', value: 'leaveHour' },
                { text: 'Date select', value: 'selectDay' },
                { text: '', value: 'actions' }
            ],
            deleteForm: {
                header: 'Delete student course',
                title: 'You want to delete student ',
                url: ''
            },
            mode: '',
            studentCourseId: '',
            studentId: '',
            studentInfo: {
                fullName: ''
            },
            breadItem: [
                {
                    text: 'Students',
                    disabled: false,
                    link: true,
                    exact: true,
                    to: {
                        path: '/students'
                    }
                },
                {
                    text: '',
                    disabled: true,
                    href: 'breadcrumbs_link_1'
                }
            ]
        }
    },

    methods: {
        async getStudentCourseList () {
            const res = await this.axios.get(`/student/${this.studentId}/student-courses`)
            if (res && res.data && res.data.status === 'ok') {
                this.studentCourseList = res.data.data
            }
        },

        backButton () {
            this.$router.go(-1)
        },
        createCourse () {
            this.$refs.studentCourseForm.dialog = true
            this.mode = 'create'
        },

        editCourse (item) {
            this.$refs.studentCourseForm.dialog = true
            this.mode = 'edit'
            this.studentCourseId = item.id.toString()
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete course ${item.course} of ${this.studentInfo.fullName} ?`
            this.deleteForm.url = `student/${this.studentId}/student-courses/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async getStudentInfo () {
            const res = await this.axios.get(`students/${this.studentId}`)
            if (res && res.data && res.data.status === 'ok') {
                const fullname = `${res.data.data.firstName} ${res.data.data.lastName}`
                this.studentInfo = {
                    fullName: fullname
                }
                this.breadItem[1].text = fullname
            }
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete branch success',
                type: 'success'
            })
            await this.getStudentCourseList()
        },

        resetValueForm () {
            this.mode = ''
            this.studentCourseId = null
        }
    },

    async mounted () {
        this.studentId = this.$route.params.id
        await this.getStudentCourseList()
        await this.getStudentInfo()
    }
}
</script>

<style lang="scss" scoped>
.student-filter-container {
    display: flex;
    align-items: center;

    .filter-search {
        width: 220px;
    }
}
</style>
